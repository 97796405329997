import { FooterHelpLink } from 'types/Footer';
import { Text } from 'ui';
import { cn } from 'utils/cn';
import { getIconStyle } from '../../../utils/iconUtil';
import { isExternalUrl } from '../../../utils/urlUtil';
import FooterCommerceBadges from '../FooterCommerceBadges/FooterCommerceBadges';
import { StyledFooterHelpSectionSections, StyledHelpSection } from './FooterHelpSection.styled';

interface FooterHelpSectionProps {
  className?: string;
  links?: FooterHelpLink[];
  title?: string;
}

const FooterHelpSection = ({ className, links, title }: FooterHelpSectionProps) => (
  <div className={cn('flex flex-col gap-5 py-7 md:pb-8 md:pt-12', className)}>
    <Text className="font-bold text-black" tag="p" type="h3">
      {title}
    </Text>

    <div className="grid gap-6 lg:grid-cols-3">
      {!!links?.length && (
        <StyledFooterHelpSectionSections className="lg:col-span-2">
          {links.map((link) => {
            const { icon, iconType, link: linkUrl, linkText, text, title: sectionTitle } = link;

            return (
              <StyledHelpSection
                key={`${linkUrl}-${linkText}`}
                copy={text}
                link={{
                  color: 'primary-300',
                  href: linkUrl,
                  icon,
                  iconStyling: iconType ? getIconStyle(iconType) : undefined,
                  label: linkText,
                  newTab: linkUrl ? isExternalUrl(linkUrl) : undefined,
                }}
                title={sectionTitle}
              />
            );
          })}
        </StyledFooterHelpSectionSections>
      )}

      <FooterCommerceBadges className="lg:col-span-1" />
    </div>
  </div>
);

export default FooterHelpSection;
