import { useIntl } from 'react-intl';
import { FooterSocialLink } from 'types/Footer';
import { Text } from 'ui';
import { cn } from 'utils/cn';
import FooterSocials from '../FooterSocials/FooterSocials';

interface FooterSocialsBarProps {
  className?: string;
  socialLinks: FooterSocialLink[];
}

const FooterSocialsBar = ({ className, socialLinks }: FooterSocialsBarProps) => {
  const { formatMessage } = useIntl();

  if (!socialLinks?.length) {
    return null;
  }

  return (
    <div
      className={cn('flex flex-col items-center gap-3 py-10 lg:flex-row lg:justify-end lg:gap-12 lg:py-4', className)}
    >
      <Text className="text-footer-socialsBar-foreground text-sm font-bold">
        {formatMessage({ id: 'footer_contact_send_message' })}
      </Text>
      <FooterSocials socialLinks={socialLinks} />
    </div>
  );
};

export default FooterSocialsBar;
